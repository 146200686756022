import React, { useState, useEffect } from 'react';
import {Stack, CircularProgress, Typography, Chip, Button} from "@mui/joy";
import { useParams } from "react-router-dom";
import {CircleDollarSign, ExternalLink} from 'lucide-react';

interface Listing {
  title: string;
  price: string;
  size: string;
  location: string;
  url: string;
  tags: string[];
  listing_details_lines: string[];
  financing_details_lines: string[];
}

const Detail = () => {
  const { id } = useParams<{ id: string }>();
  const [listing, setListing] = useState<Listing | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchListing = async () => {
      try {
        const response = await fetch(`/data/listings/${id}.json`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: Listing = await response.json();
        setListing(data);
        console.log(listing, data);
      } catch (e) {
        setError('Failed to fetch listing details. Please try again later.');
        console.error("Fetch error:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListing();
  }, [id]);

  if (isLoading || !listing) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50vh">
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50vh">
        <Typography color="danger">{error}</Typography>
      </Stack>
    );
  }

  console.log(listing.url);
  return (
    <>
      <Typography level="h3">{listing.title}</Typography>
      <Stack direction={"column"} spacing={2}>
        <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
          <Typography>{listing.price} &middot; {listing.size} &middot; {listing.location}</Typography>
          {listing.tags.map((tag, index) => (
            <Chip key={index} size="sm">{tag}</Chip>
          ))}
        </Stack>
          <Stack direction={"column"} spacing={1}
            sx={{
              p: 2,
              border: "solid 1px green",
              borderRadius: "md",
              backgroundColor: "#00FF0008",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CircleDollarSign />
              <Typography level="h4">Financing Details</Typography>
            </Stack>
            {listing.financing_details_lines.map((line, index) => (
              <Typography key={index}>{line}</Typography>
            ))}
          </Stack>
          {listing.listing_details_lines.map((line, index) => (
            <Typography key={index}>{line}</Typography>
          ))}
          <Stack direction="row">
            <Button
              component="a"
              href={listing.url}
              target="_blank"
              rel="noopener noreferrer"
              endDecorator={<ExternalLink size={16} />}
            >
              View Original Listing
            </Button>
          </Stack>
      </Stack>
    </>
  );
};

export default Detail;