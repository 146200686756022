import React from 'react';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import Index from './pages/Index';
import Detail from './pages/Detail';
import PrimaryLayout from './layouts/Primary';

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PrimaryLayout />,
    children: [
      {
        path: '/',
        element: <Index />,
      },
      {
        path: '/:id',
        element: <Detail />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
