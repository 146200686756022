import {Box, Link} from "@mui/joy";
import {Outlet} from "react-router-dom";

const PrimaryLayout: React.FC = () => {
  return (
    <Box
      sx={{
        maxWidth: '700px',
        margin: 'auto',
        my: 3,
      }}>
      <Box
        sx={{
          mb: 3,
        }}
      >
        <Link href={"/"} sx={{ m: 0, p: 0 }}>
          <Box
            sx={{
              fontSize: '30px',
              display: 'inline',
              backgroundColor: 'darkgreen',
              pl: 2,
              pb: 1,
              pr: 2,
              pt: 0.5,
              borderRadius: '0 26px 0 26px',
              color: '#eee',
            }}
          >
            ofa
          </Box>
        </Link>
      </Box>
      <Box>
        <Outlet />
      </Box>
      <Box sx={{ mt: 2 }}>
        &copy;2024 Owner Financed Alaska
      </Box>
    </Box>
  );
}

export default PrimaryLayout;
