import React from "react";
import { Box, Chip, Stack, Typography, Link } from "@mui/joy";
import { Listing as ListingType } from '../types/listing'

interface ListingProps {
  listing: ListingType,
}

const Listing: React.FC<ListingProps> = ({ listing }) => {
  return (
    <Box>
      <Link
        href={`/${listing.id}`}  // Adjust this URL as needed
        underline="none"
        color="neutral"
      >
        <Typography level="h3">{listing.title}</Typography>
      </Link>
      <Stack direction="row" spacing={1} mt={1} flexWrap="wrap">
        <Typography>{listing.price} &middot; {listing.size} &middot; {listing.location}</Typography>
        {listing.tags.map((tag, index) => (
          <Chip key={index} size="sm">{tag}</Chip>
        ))}
      </Stack>
    </Box>
  );
};

export default Listing;