import React, { useState, useEffect } from 'react';
import { Stack, CircularProgress, Typography, Button } from "@mui/joy";
import Listing from "../components/Listing";

const ITEMS_PER_PAGE = 20;

const Listings = () => {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const response = await fetch('/data/manifest.json');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setListings(data.listings);
      } catch (e) {
        setError('Failed to fetch listings. Please try again later.');
        console.error("Fetch error:", e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, []);

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50vh">
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return (
      <Stack alignItems="center" justifyContent="center" height="50vh">
        <Typography color="danger">{error}</Typography>
      </Stack>
    );
  }

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentListings = listings.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(listings.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Stack spacing={2}>
      {currentListings.map((listing, index) => (
        <Listing key={index} listing={listing} />
      ))}
      <Stack direction="row" spacing={3} justifyContent="center" alignItems={"center"} mt={2}>
        <Button
          variant={"outlined"}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &larr;
        </Button>
        <Typography>
          Page {currentPage} of {totalPages}
        </Typography>
        <Button
          variant={"outlined"}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &rarr;
        </Button>
      </Stack>
    </Stack>
  );
};

export default Listings;